import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { AuthContext } from 'auth-context';
import { Inputs } from 'components';
import { useContext } from 'react';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
	container: {
		padding: theme.spacing(6),
		paddingTop: theme.spacing(24),
	}
}));

const Login = () => {
	const classes = useStyles();
	const history = useHistory();
	const { isNewUser, signInDetails, setSignInDetails, signIn } = useContext(AuthContext);
	const { email, password } = signInDetails;

	if (isNewUser) {
		history.push('/set-password');
	}

	return (
		<Container className={classes.container} maxWidth="xs">
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<Inputs.Email
								value={email}
								onChange={(e: any) =>
									setSignInDetails((pv: any) => ({
										...pv,
										email: e.target.value,
									}))
								}
							/>
						</Grid>
						<Grid item xs={12}>
							<Inputs.Password
								value={password}
								onChange={(e: any) =>
									setSignInDetails((pv: any) => ({
										...pv,
										password: e.target.value,
									}))
								}
							/>
						</Grid>
						<Grid item xs={12}>
							<div
								onClick={() => history.push("/reset-password")}
								style={{ fontSize: "18px", cursor: "pointer" }}>
								Reset Password
							</div>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={12}>
					<Button
						color="secondary"
						disabled={password?.length < 8 || !email}
						onClick={signIn}
						fullWidth
						type="submit"
						variant="contained">
						Log in
					</Button>
				</Grid>
			</Grid>
		</Container>
	);
};

export default Login;