/* eslint-disable react/prop-types */
import {
  INVALID_PASSWORD,
  NEW_PASSWORD_REQUIRED_ERROR,
  WRONG_CREDS_ERROR,
} from "utils";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { AWSService } from "services";
import { completeNewPasswordChallenge, signInWithEmail } from "services/aws";

export enum AuthStatus {
  Loading,
  SignedIn,
  SignedOut,
}

export interface IAuth {
  userAttributes?: any;
  sessionInfo?: {
    username?: string;
    email?: string;
    sub?: string;
    accessToken?: string;
    refreshToken?: string;
  };
  attrInfo?: any;
  authStatus?: AuthStatus;
  signIn?: any;
  isNewUser?: boolean;
  setSignInDetails: any;
  setNewPassword?: any;
  signInDetails: { password: string; email: string };
  newPassword: string;
  signOut?: any;
  completeNewPassword?: any;
  verifyCode?: any;
  getSession?: any;
  sendCode?: any;
  resetPassword?: any;
  changePassword?: any;
  getAttributes?: any;
  setAttribute?: any;
  resetPasswordDetails?: any;
  setResetPasswordDetails?: any;
}

const defaultState: IAuth = {
  sessionInfo: {},
  authStatus: AuthStatus.Loading,
  signInDetails: { password: "", email: "" },
  setSignInDetails: () => {},
  newPassword: "",
};

export const AuthContext = React.createContext(defaultState);

const AuthProvider: React.FunctionComponent = ({ children }) => {
  const [authStatus, setAuthStatus] = useState(AuthStatus.Loading);
  const [sessionInfo, setSessionInfo] = useState({});
  const [resetPasswordDetails, setResetPasswordDetails] = useState({
    code: "",
    email: "",
    newPassword: "",
  });
  const [signInDetails, setSignInDetails] = useState({
    email: "",
    password: "",
  });
  const [newPassword, setNewPassword] = useState("");
  const [isNewUser, setIsNewUser] = useState(false);
  const [userAttributes, setUserAttributes] = useState({});
  const history = useHistory();

  useEffect(() => {
    async function getSessionInfo() {
      try {
        const session: any = await getSession();
        console.log("🚀 ~ session", session.accessToken.payload.username);
        setSessionInfo({
          accessToken: session?.accessToken?.jwtToken,
          refreshToken: session?.refreshToken?.token,
        });
        window.localStorage.setItem(
          "accessToken",
          `${session.accessToken.jwtToken}`
        );
        window.localStorage.setItem(
          "refreshToken",
          `${session.refreshToken.token}`
        );
        setAuthStatus(AuthStatus.SignedIn);
        setUserAttributes((pv: any) => ({
          ...pv,
          username: session?.accessToken?.payload?.username,
        }));
      } catch (err) {
        console.log("err", err);
        setAuthStatus(AuthStatus.SignedOut);
      }
    }
    getSessionInfo();
  }, [authStatus]);

  const completeNewPassword = async () => {
    const response: any = await completeNewPasswordChallenge(
      userAttributes,
      newPassword
    );
    if (!response.success) {
      if (response.data.error.code === INVALID_PASSWORD) {
        toast.error(
          "The new password length should be at least 8 with combination of lower, capital and special chars"
        );
      } else {
        toast.error("Something went wrong! Please try again");
        console.log("Error in completeNewPassword", response?.data?.error);
      }
    } else {
      toast.success("New Password Set Successfully!", {
        autoClose: false,
        hideProgressBar: true,
      });
      setAuthStatus(AuthStatus.SignedIn);
    }
  };

  const signIn = async () => {
    const { email, password } = signInDetails;
    const response: any = await signInWithEmail(email, password);
    if (!response.success) {
      if (response?.data?.error?.message === NEW_PASSWORD_REQUIRED_ERROR) {
        setUserAttributes(response?.data?.attributes);
        setIsNewUser(true);
        return;
      }
      if (response?.data?.error?.message === WRONG_CREDS_ERROR) {
        toast.error("Wrong Username or Password. Please Try Again.");
        return;
      }
    }
    if (response?.success) {
      setAuthStatus(AuthStatus.SignedIn);
      window.location.reload();
    }
  };

  function signOut() {
    AWSService.signOut();
    setAuthStatus(AuthStatus.SignedOut);
  }

  async function getSession() {
    const session = await AWSService.getSession();
    return session;
  }

  async function resetPassword() {
    const {
      email: username,
      code,
      newPassword: password,
    } = resetPasswordDetails;
    const { success } = await AWSService.forgotPassword(
      username,
      code,
      password
    );
    if (success) {
      toast.success(
        "Password Reseted Successfully! Please Login With The New Password"
      );
      history.push("/signin");
    }
  }

  async function changePassword(
    username: string,
    oldPassword: string,
    newPassword: string
  ) {
    await AWSService.changePassword(username, oldPassword, newPassword);
  }

  const state: IAuth = {
    userAttributes,
    authStatus,
    sessionInfo,
    signIn,
    signOut,
    getSession,
    isNewUser,
    setSignInDetails,
    completeNewPassword,
    setNewPassword,
    signInDetails,
    newPassword,
    resetPassword,
    resetPasswordDetails,
    setResetPasswordDetails,
    changePassword,
  };

  return <AuthContext.Provider value={state}>{children}</AuthContext.Provider>;
};

export default AuthProvider;

// const signInWithEmail = async (email: string, password: string) => {
//   try {
//     await AWSService.signInWithEmail(email, password)
//     setAuthStatus(AuthStatus.SignedIn)
//   } catch (err) {
//     setAuthStatus(AuthStatus.SignedOut)
//     throw err
//   }
// }
