import { BotsAPI } from "api";
import { AuthContext } from "auth-context";
import { useContext, useEffect, useState } from "react";
import { useStore } from "react-hookstore";
import { toast } from "react-toastify";
import { API_STORE } from "store";
import { APIStore, Platform } from "types";

export const useRegions = () => {
  const [regions, setRegions] = useState([]);
  useEffect(() => {
    const getRegions = async () => {
      try {
        const response = await BotsAPI.getRegions(Platform.DIGITAL_OCEAN);
        if (response.status === 200) {
          setRegions(response.data.regions);
        }
      } catch (err) {
        console.log("err", err);
      }
    };

    getRegions();
  }, []);

  return regions;
};

export const useBots = () => {
  const { authStatus } = useContext(AuthContext);
  const [, setAPIStore] = useStore(API_STORE);
  const handler = async () => {
    const { data, rb0Available, rb1Available, rb2Available } =
      await BotsAPI.getBotsByTag();
    //@ts-ignore
    setAPIStore({
      bots: data,
      rb2Available,
      rb1Available,
      rb0Available,
    });
  };
  useEffect(() => {
    toast.info("LODING BOTS LIST", { autoClose: 3500 });
    handler();
  }, []);
};

export const updateBots = () => {
  const { authStatus } = useContext(AuthContext);
  const [{ refetchAllDroplets }, setAPIStore]: [APIStore, any] =
    useStore(API_STORE);
  const handler = async () => {
    const { data, rb0Available, rb1Available, rb2Available } =
      await BotsAPI.getBotsByTag();
    setAPIStore({
      refetchAllDroplets: false,
      bots: data,
      rb2Available,
      rb1Available,
      rb0Available,
    });
  };
  useEffect(() => {
    if (refetchAllDroplets) {
      toast.info("UPDATING BOTS LIST", { autoClose: 3500 });
      handler();
    }
  }, [refetchAllDroplets]);
};
