import styled from 'styled-components';

export const SC = {
	NewProjectWrapper: styled.div`
		display: grid;
	`,
	ButtonWrapper: styled.div`
		display: grid;
		justify-content: center;
	`,
	InputWrapper: styled.div`
		width: 255px;
	`
};