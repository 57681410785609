import { FormattedBot } from "api/formatters/bots";

export enum Platform {
  DIGITAL_OCEAN = "digital_ocean",
  AWS = "aws",
  IBM = "ibm",
  AZURE = "azure",
  GCP = "gcp",
  VULTR = "vultr",
  UPCLOUD = "upcloud",
}

export enum WebCliBotnetCreationVariant {
  CreateCustom,
  CreateDefault,
  CreatePredefined,
}

export type Regions =
  | typeof BLR
  | typeof TOR
  | typeof SGP
  | typeof FRA
  | typeof SFO
  | typeof LON
  | typeof AMS
  | typeof NYC;

export type BHS = 1 | 2 | 3;

export const NYC = "NYC";
export const AMS = "AMS";
export const LON = "LON";
export const SFO = "SFO";
export const FRA = "FRA";
export const SGP = "SGP";
export const TOR = "TOR";
export const BLR = "BLR";

export interface CreateBots {
  size: "small" | "medium" | "large" | "xlarge";
  region: Regions;
  bh: BHS;
  rb: 0 | 1 | 2;
  quantity: number;
}

export interface CreateBotsPayload {
  botnet_name: string;
  herder: number;
  machine_type: string;
  platform: Platform;
  platform_account: string;
  distribution: { region: string; amount: number }[];
}

export interface DeleteBot {
  imageId: BotImage;
  botId: string;
}

export interface KeyValuePair {
  key: string;
  value: string;
}

export interface RestartBot {
  imageId: BotImage;
  botId: string;
}

export interface APIStore {
  refetchAllDroplets: boolean;
  bots: FormattedBot[];
  rb1Available: number;
  rb0Available: number;
  rb2Available: number;
}
export interface PredefinedBotArray {
  name: string;
  description: string;
  id: string;
}

export interface WebCLIMenuItem {
  text: string;
  action: (input: string) => void | Promise<void>;
  validateInput?: (input: string) => boolean;
  errorMessage?: string;
}

export interface WebCLIMenuState {
  botnetCreationVariant?: WebCliBotnetCreationVariant;
  botnetHerderId?: string;
  botnetMachineType?: string;
  botnetName: string;
  botPlatform?: Platform;
  botPlatformAccount?: string;
  botPlatformMachineTypes?: KeyValuePair[];
  botPlatformRegions?: KeyValuePair[];
  herderCount: number;
  predefinedItem?: PredefinedBotArray;
  predefinedList: PredefinedBotArray[];
}

export type BotImage =
  | typeof rb0bh1
  | typeof rb0bh2
  | typeof rb0bh3
  | typeof rb1bh1
  | typeof rb1bh2
  | typeof rb1bh3
  | typeof rb2bh1
  | typeof rb2bh2
  | typeof rb2bh3;

// #image ids
export const rb0bh1 = "72121017";
export const rb0bh2 = "72122910";
export const rb0bh3 = "74471648";

export const rb1bh1 = "72120916";
export const rb1bh2 = "72123053";
export const rb1bh3 = "74471657";

export const rb2bh1 = "72120849";
export const rb2bh2 = "72123288";
export const rb2bh3 = "74471656";

export enum Herder {
  One = "i-0730fd22c2ef693a1",
  Two = "i-0d3d692e49aeb9c8e",
  Three = "i-0b5a5b13983e0bd6a",
}

export const MapHerder = [
  "i-0730fd22c2ef693a1",
  "i-0d3d692e49aeb9c8e",
  "i-0b5a5b13983e0bd6a",
];

export enum HerderState {
  Pending = 0,
  Running = 16,
  Stopping = 64,
  Stopped = 80,
}

export const isHerderOn: any = {
  0: false, //pending
  16: true, //running
  64: false, //stopping
  80: false, //stopped
};

export interface Instance {
  id: string;
  name: string;
  region: string;
  status: string;
  ip_address: string;
}
