import { BotsAPI, HerdersAPI } from "api";
import {
  cliBotMenuText,
  cliBotnetNameMenuText,
  cliBotPlatformsMenuText,
  cliDeleteBotsMenuText,
  cliFirstMenuText,
  cliHerderMenuText,
  cliResetartBotsMenuText,
} from "utils";
import {
  Platform,
  WebCliBotnetCreationVariant,
  WebCLIMenuItem,
  WebCLIMenuState,
} from "types";

type Menu = {
  [key: string]: WebCLIMenuItem | ((state: WebCLIMenuState) => WebCLIMenuItem);
};

export function createMenu(
  goToMenuItem: ({
    key,
    label,
    state,
  }: {
    key: string;
    label?: string;
    state?: Partial<WebCLIMenuState>;
  }) => void
): Menu {
  return {
    "0": (state: WebCLIMenuState) => ({
      text: cliFirstMenuText,
      validateInput(input: string) {
        return ["1", "2", "3", "q"].includes(input);
      },
      async action(input: string) {
        if (input === "q") {
          return goToMenuItem({ key: "0" });
        } else if (input === "3") {
          const items = await BotsAPI.getPredefinedBotArrays();
          state.predefinedList = items;
          return goToMenuItem({ key: "3", state });
        }
        goToMenuItem({ key: input });
      },
      errorMessage:
        "Invalid input. Please enter a number between 1 and 3 or q to quit",
    }),
    // control herders
    "1": (state: WebCLIMenuState) => ({
      text: cliHerderMenuText,
      validateInput(input: string) {
        return ["1", "2", "3", "q"].includes(input);
      },
      errorMessage:
        "Invalid input. Please enter a number between 1 and 3 or q to quit",
      async action(input: string) {
        if (input === "q") {
          return goToMenuItem({ key: "0" });
        }
        const herders = await HerdersAPI.describe();
        state.herderCount = herders.length;
        if (input === "3") {
          //print herders (menuItem #1.3)
          const label = herders
            .map(
              (herder) =>
                `name:${herder.name}, ip:${herder.ip_address}, region:${herder.region}, id:${herder.id}, status:${herder.status}`
            )
            .join("\n");
          return goToMenuItem({ key: "0", label });
        }
        goToMenuItem({ key: `1.${input}`, state });
      },
    }),
    "2": {
      //control bots
      text: cliBotPlatformsMenuText,
      errorMessage:
        "Invalid input. Please enter a number between 1 and 6 or q to quit",
      validateInput(input: string) {
        return Array.from({ length: 6 }, (_, index) => (index + 1).toString())
          .concat("q")
          .includes(input);
      },
      action(input: string) {
        if (input === "q") {
          return goToMenuItem({ key: "0" });
        }
        const botPlatformByInput = {
          "1": Platform.DIGITAL_OCEAN,
          "2": Platform.IBM,
          "3": Platform.AZURE,
          "4": Platform.VULTR,
          "5": Platform.GCP,
          "6": Platform.UPCLOUD,
        };
        const botPlatform =
          botPlatformByInput[input as "1" | "2" | "3" | "4" | "5" | "6"];
        if (input === "1") {
          return goToMenuItem({ key: "2.1", state: { botPlatform } });
        }
        return goToMenuItem({ key: `2.x`, state: { botPlatform } });
      },
    },
    // Predefined Bot Array
    "3": (state: WebCLIMenuState) => ({
      text: `\n${state.predefinedList
        .map(
          (item, index) => `[${index + 1}] ${item.name}: ${item.description}`
        )
        .join("\n")}\n[q] Return to main menu`,
      action(input: string) {
        if (input === "q") {
          return goToMenuItem({ key: "0" });
        }
        state.predefinedItem = state.predefinedList[Number(input) - 1];
        goToMenuItem({ key: "3.1", state });
      },
      validateInput(input: string) {
        return [
          ...Array.from({ length: state.predefinedList.length }, (_, index) =>
            (index + 1).toString()
          ),
          "q",
        ].includes(input);
      },
      errorMessage: `Invalid input. Please enter a number between 1 and ${state.predefinedList.length} or q to quit.`,
    }),
    "1.1": (state: WebCLIMenuState) => /*start herders*/ ({
      async action(input: string) {
        await HerdersAPI.start(
          input
            .split(" ")
            .map((id) => id.trim())
            .filter((id) => id)
        );
        goToMenuItem({ key: "1" });
      },
      validateInput(input: string) {
        const herdersId = input
          .split(" ")
          .map((id) => id.trim())
          .filter((id) => id);
        return herdersId.every((id) => {
          const number = typeof id === "number" ? id : Number(id);
          return !isNaN(number) && number <= state.herderCount;
        });
      },
      text: `Start Herder - Insert herder numbers between 1 and ${state.herderCount}, separated by spaces (e.g., 1 2 3 ...)`,
      errorMessage: `Invalid input. Please enter herder id between 1 and ${state.herderCount}`,
    }),
    "1.2": (state: WebCLIMenuState) => ({
      async action(input: string) {
        await HerdersAPI.stop(
          input
            .split(" ")
            .map((id) => id.trim())
            .filter((id) => id)
        );
        goToMenuItem({ key: "1" });
      },
      validateInput(input: string) {
        const herdersId = input
          .split(" ")
          .map((id) => id.trim())
          .filter((id) => id);
        return herdersId.every((id) => {
          const number = typeof id === "number" ? id : Number(id);
          return !isNaN(number) && number <= state.herderCount;
        });
      },
      text: `Stop Herder - Insert herder numbers between 1 and ${state.herderCount}, separated by spaces (e.g., 1 2 3 ...)`,
      errorMessage: `Invalid input. Please enter herder id between 1 and ${state.herderCount}`,
    }),
    "2.1": (state: WebCLIMenuState) => ({
      text: "Select RB acount (0/1/2):",
      validateInput(input: string) {
        return ["0", "1", "2"].includes(input);
      },
      errorMessage: "Invalid input. Please enter 0, 1 or 2.",
      action(input) {
        state.botPlatformAccount = input;
        goToMenuItem({ key: "2.x", state });
      },
    }),
    "2.x": (state: WebCLIMenuState) => ({
      text: cliBotMenuText,
      validateInput(input: string) {
        return ["1", "2", "3", "4", "q"].includes(input);
      },
      errorMessage:
        "Invalid input. Please enter a number between 1 and 4 or q to quit",
      async action(input: string) {
        if (input === "q") {
          return goToMenuItem({ key: "0" });
        }
        if (input === "4") {
          const bots = await BotsAPI.getBotsByPlatform({
            platform: state.botPlatform ?? Platform.DIGITAL_OCEAN,
            platformAccount: state.botPlatformAccount ?? "",
          });
          console.info(bots);
          const label =
            bots
              .map(
                (bot) =>
                  `name:${bot.name}, ip:${bot.ip_address}, region:${bot.region}, id:${bot.id}, status:${bot.status}`
              )
              .join("\n") + `\nTotal bots: ${bots.length}\n`;
          return goToMenuItem({ key: "0", label });
        }
        goToMenuItem({ key: `2.x.${input}`, state });
      },
    }),
    "3.1": (state: WebCLIMenuState) => ({
      text: "Select botnet name (consist of lowercase letters (a-z) and numbers): ",
      validateInput(input: string) {
        return /^[a-z0-9]+$/.test(input);
      },
      async action(input) {
        console.info("predefined", state.predefinedItem);
        if (!state.predefinedItem) {
          return;
        }
        await BotsAPI.createPredefined({
          botnetName: input,
          id: state.predefinedItem.id,
        });
        goToMenuItem({ key: "0" });
      },
      errorMessage:
        "Invalid input. Bot name can consist only lowercase letters (a-z) and numbers.",
    }),
    "2.x.1": (state: WebCLIMenuState) => ({
      text: cliBotnetNameMenuText,
      validateInput(input: string) {
        return /^[a-z0-9]+$/.test(input);
      },
      async action(input: string) {
        state.botnetName = input;
        state.herderCount = (await HerdersAPI.describe()).length;
        goToMenuItem({ key: "2.x.1.1", state });
      },
      errorMessage: "Invalid input. Please enter an alphanumeric string.",
    }),
    "2.x.2": (state: WebCLIMenuState) => ({
      text: cliResetartBotsMenuText,
      validateInput(input: string) {
        return ["1", "2", "3", "q"].includes(input);
      },
      errorMessage:
        "Invalid input. Please enter a number between 1 and 3 or q to quit",
      async action(input: string) {
        if (input === "q") {
          return goToMenuItem({ key: "0" });
        }
        if (input === "1") {
          await BotsAPI.restartWhere({
            platform: state.botPlatform ?? Platform.DIGITAL_OCEAN,
            platformAccount: state.botPlatformAccount || "",
          });
          return goToMenuItem({ key: "0", label: "All bots restarted." });
        }
        if (input === "2") {
          state.botPlatformRegions = await BotsAPI.getRegions(
            state.botPlatform ?? Platform.DIGITAL_OCEAN
          );
        }
        goToMenuItem({ key: `2.x.2.${input}`, state });
      },
    }),
    "2.x.3": (state: WebCLIMenuState) => ({
      text: cliDeleteBotsMenuText,
      validateInput(input: string) {
        return ["1", "2", "3", "q"].includes(input);
      },
      errorMessage:
        "Invalid input. Please enter a number between 1 and 3 or q to quit",
      async action(input: string) {
        if (input === "q") {
          return goToMenuItem({ key: "0" });
        }
        if (input === "1") {
          await BotsAPI.deleteWhere({
            platform: state.botPlatform ?? Platform.DIGITAL_OCEAN,
            platformAccount: state.botPlatformAccount || "",
          });
          return goToMenuItem({
            key: "0",
            label: "Request to delete all bots was issued.",
          });
        }
        if (input === "2") {
          state.botPlatformRegions = await BotsAPI.getRegions(
            state.botPlatform ?? Platform.DIGITAL_OCEAN
          );
        }
        goToMenuItem({ key: `2.x.3.${input}`, state });
      },
    }),
    "2.x.1.1": (state: WebCLIMenuState) => ({
      text: `Choose herder between 1 and ${state.herderCount}`,
      validateInput(input: string) {
        return (
          Number(input) > 0 &&
          Number(input) <= state.herderCount &&
          !isNaN(Number(input))
        );
      },
      async action(input: string) {
        state.botnetHerderId = input;
        state.botPlatformMachineTypes = await BotsAPI.getMachineTypes(
          state.botPlatform ?? Platform.DIGITAL_OCEAN
        );
        goToMenuItem({ key: "2.x.1.1.1", state });
      },
      errorMessage: `Invalid input. Please enter a number between 1 and ${state.herderCount}`,
    }),
    "2.x.2.2": (state: WebCLIMenuState) => ({
      text: `Please choose a region:\n${state.botPlatformRegions
        ?.map(
          (region, index) => `[${index + 1}] ${region.key} (${region.value})`
        )
        .join("\n")}`,
      validateInput(input: string) {
        return (
          Number(input) > 0 &&
          Number(input) <= (state.botPlatformRegions?.length || 0) &&
          !isNaN(Number(input))
        );
      },
      async action(input: string) {
        await BotsAPI.restartWhere({
          platform: state.botPlatform ?? Platform.DIGITAL_OCEAN,
          platformAccount: state.botPlatformAccount || "",
          region: state.botPlatformRegions?.[Number(input) - 1].key,
        });
        goToMenuItem({ key: "0", label: "Bots restarted." });
      },
      errorMessage: `Invalid input. Please enter a number between 1 and ${
        state.botPlatformRegions?.length || 0
      }`,
    }),
    "2.x.2.3": (state: WebCLIMenuState) => ({
      text: "Please enter the botnet name to restart:",
      validateInput(input: string) {
        return /^[a-z0-9]+$/.test(input);
      },
      async action(input: string) {
        await BotsAPI.restartWhere({
          platform: state.botPlatform ?? Platform.DIGITAL_OCEAN,
          platformAccount: state.botPlatformAccount || "",
          name: input,
        });
        goToMenuItem({ key: "0", label: "Bots restarted." });
      },
      errorMessage: "Invalid input. Please enter an alphanumeric string.",
    }),
    "2.x.3.2": (state: WebCLIMenuState) => ({
      text: `Please choose a region:\n${state.botPlatformRegions
        ?.map(
          (region, index) => `[${index + 1}] ${region.key} (${region.value})`
        )
        .join("\n")}`,
      validateInput(input: string) {
        return (
          Number(input) > 0 &&
          Number(input) <= (state.botPlatformRegions?.length || 0) &&
          !isNaN(Number(input))
        );
      },
      async action(input: string) {
        await BotsAPI.deleteWhere({
          platform: state.botPlatform ?? Platform.DIGITAL_OCEAN,
          platformAccount: state.botPlatformAccount || "",
          region: state.botPlatformRegions?.[Number(input) - 1].key,
        });
        goToMenuItem({ key: "0", label: "Delete request issued." });
      },
      errorMessage: `Invalid input. Please enter a number between 1 and ${
        state.botPlatformRegions?.length || 0
      }`,
    }),
    "2.x.3.3": (state: WebCLIMenuState) => ({
      text: "Please enter the botnet name to delete:",
      validateInput(input: string) {
        return /^[a-z0-9]+$/.test(input);
      },
      async action(input: string) {
        await BotsAPI.deleteWhere({
          platform: state.botPlatform ?? Platform.DIGITAL_OCEAN,
          platformAccount: state.botPlatformAccount || "",
          name: input,
        });
        goToMenuItem({ key: "0", label: "Delete request issued." });
      },
      errorMessage: "Invalid input. Please enter an alphanumeric string.",
    }),
    "2.x.1.1.1": (state: WebCLIMenuState) => ({
      text: `${state.botPlatformMachineTypes
        ?.map(
          (type, index) =>
            `[${index + 1}] ${type.value}, Machine type: ${type.key}`
        )
        .join("\n")}\nSelect machine type:`,
      validateInput(input: string) {
        return (
          Number(input) > 0 &&
          Number(input) <= (state.botPlatformMachineTypes?.length || 0) &&
          !isNaN(Number(input))
        );
      },
      async action(input: string) {
        if (!state.botPlatform) {
          return;
        }
        state.botnetMachineType =
          state.botPlatformMachineTypes?.[Number(input) - 1].key;
        state.botPlatformRegions = await BotsAPI.getRegions(
          state.botPlatform ?? Platform.DIGITAL_OCEAN
        );
        goToMenuItem({ key: "2.x.1.1.1.x", state });
      },
      errorMessage: `Invalid input. Please enter a number between 1 and ${
        state.botPlatformMachineTypes?.length || 0
      }`,
    }),
    "2.x.1.1.1.x": (state: WebCLIMenuState) => ({
      text: `These are the available regions for ${
        state.botPlatform
      }:\n${state.botPlatformRegions
        ?.map(
          (region, index) => `[${index + 1}] ${region.key} (${region.value})`
        )
        .join("\n")}
For every region you want to add, enter the number of desired bots for that region, separated by a comma.
For example: the input 1,2,3, will create 1 bot in ${
        state.botPlatformRegions?.[0].value
      }, 2 bots in ${state.botPlatformRegions?.[1].value} and 3 bots in ${
        state.botPlatformRegions?.[2].value
      }.`,
      validateInput(input: string) {
        return input.split(",").every((value) => !isNaN(Number(value)));
      },
      async action(input: string) {
        const distribution: { region: string; amount: number }[] = input
          .split(",")
          .map((value, index) => ({
            region: state.botPlatformRegions?.[index]?.key || "",
            amount: Number(value),
          }));
        await BotsAPI.createBots({
          botnet_name: state.botnetName,
          herder: Number(state.botnetHerderId),
          machine_type: state.botnetMachineType || "",
          platform: state.botPlatform || Platform.DIGITAL_OCEAN,
          platform_account: state.botPlatformAccount || "",
          distribution,
        });
        goToMenuItem({
          key: "0",
          label: "Creating bots... What else would you like to do?",
        });
      },
      errorMessage: `Invalid input. Please enter a list of numbers separated by commas.`,
    }),
  };
}
