import Terminal from "components/Terminal";
import React from "react";
import { WebCLIMenuItem, WebCLIMenuState } from "types";
import { cliInitialMenuText, createMenu, defaultWebCLIMenuState } from "utils";

export const WebCLI: React.FC = () => {
  const [menu] = React.useState(createMenu(goToMenuItem));

  const [currentMenuItem, setCurrentMenuItem] = React.useState<WebCLIMenuItem>(
    typeof menu[0] === "function" ? menu[0](defaultWebCLIMenuState) : menu[0]
  );
  const [terminalLabel, setTerminalLabel] = React.useState(cliInitialMenuText);

  function goToMenuItem({
    key,
    label = "",
    state,
  }: {
    key: string;
    label?: string;
    state?: Partial<WebCLIMenuState>;
  }) {
    const nextMenuItem = menu[key];
    if (!nextMenuItem) {
      return;
    }
    const menuItem =
      typeof nextMenuItem === "function"
        ? nextMenuItem({ ...defaultWebCLIMenuState, ...state })
        : nextMenuItem;
    setCurrentMenuItem(menuItem);
    setTerminalLabel(`${label}\n${menuItem.text}`);
  }

  function handleChange(input: string) {
    if (!input) {
      return;
    }
    const isInputValid =
      !currentMenuItem?.validateInput || currentMenuItem?.validateInput(input);
    if (isInputValid) {
      return currentMenuItem?.action(input);
    }
    if (currentMenuItem?.errorMessage) {
      return setTerminalLabel(currentMenuItem.errorMessage);
    }
  }

  return <Terminal label={terminalLabel} onChange={handleChange} />;
};
