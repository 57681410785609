import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserPool,
  ICognitoUserPoolData,
} from "amazon-cognito-identity-js";
import { NEW_PASSWORD_REQUIRED_ERROR } from "utils";

const poolData: ICognitoUserPoolData = {
  UserPoolId: process.env.REACT_APP_USERPOOL_ID || "",
  ClientId: process.env.REACT_APP_CLIENT_ID || "",
};

const userPool: CognitoUserPool = new CognitoUserPool(poolData);

let currentUser: any = userPool.getCurrentUser();

export function getCurrentUser() {
  return currentUser;
}

const getCognitoUser = (username: string) => {
  const userData = {
    Username: username,
    Pool: userPool,
  };
  const cognitoUser = new CognitoUser(userData);

  return cognitoUser;
};

export async function getUserAccessToken() {
  const currentUser = userPool.getCurrentUser();

  //@ts-ignore
  return currentUser?.storage?.accessToken;
}

export async function getSession() {
  const currentUser = userPool.getCurrentUser();

  return new Promise(function (resolve, reject) {
    currentUser?.getSession(function (err: any, session: any) {
      if (err) {
        reject(err);
      } else {
        resolve(session);
      }
    });
  }).catch((err) => {
    throw err;
  });
}

export async function signInWithEmail(username: string, password: string) {
  return new Promise(function (resolve, reject) {
    const authenticationData = {
      Username: username,
      Password: password,
    };
    const authenticationDetails = new AuthenticationDetails(authenticationData);
    currentUser = getCognitoUser(username);

    return currentUser.authenticateUser(authenticationDetails, {
      onSuccess: function (res: any) {
        resolve({ success: true, data: res });
      },
      onFailure: function (error: any) {
        resolve({
          success: false,
          data: { error: { message: error.message } },
        });
      },
      newPasswordRequired: function (
        userAttributes: any,
        requiredAttributes: any
      ) {
        resolve({
          success: false,
          data: {
            error: { message: NEW_PASSWORD_REQUIRED_ERROR },
            attributes: requiredAttributes,
          },
        });
      },
    });
  }).catch((err) => {
    throw err;
  });
}

export function signOut() {
  const currentUser = userPool.getCurrentUser();
  if (currentUser) {
    currentUser.signOut();
  }
}

export async function forgotPassword(
  username: string,
  code: string,
  password: string
): Promise<{ success: boolean }> {
  return new Promise(function (resolve, reject) {
    const currentUser = getCognitoUser(username);

    if (!currentUser) {
      resolve({ success: false });
      return;
    }

    currentUser.confirmPassword(code, password, {
      onSuccess: function () {
        resolve({ success: true });
      },
      onFailure: function (err) {
        resolve({ success: false });
      },
    });
  });
}

export async function getAttributes() {
  return new Promise(function (resolve, reject) {
    currentUser.getUserAttributes(function (err: any, attributes: any) {
      if (err) {
        reject(err);
      } else {
        resolve(attributes);
      }
    });
  }).catch((err) => {
    throw err;
  });
}

export function changePassword(
  username: string,
  oldPassword: string,
  newPassword: string
) {
  return new Promise(function (resolve, reject) {
    const currentUser = getCognitoUser(username);
    currentUser.changePassword(
      oldPassword,
      newPassword,
      function (err: any, res: any) {
        if (err) {
          reject(err);
        } else {
          resolve(res);
        }
      }
    );
  });
}

export function completeNewPasswordChallenge(
  userAttr: any,
  newPassword: string
): Promise<{ success: boolean; data?: { error: any } }> {
  return new Promise(function (resolve, reject) {
    currentUser.completeNewPasswordChallenge(newPassword, userAttr, {
      onFailure: (error: any) => {
        resolve({ success: false, data: { error } });
      },
      onSuccess: () => {
        resolve({ success: true });
      },
    });
  });
}
