import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { AuthContext } from "auth-context";
import { useContext } from "react";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
	container: {
		padding: theme.spacing(6),
		paddingTop: theme.spacing(24),
	},
}));

const ResetPassword = () => {
	const { resetPasswordDetails, setResetPasswordDetails, resetPassword } = useContext(AuthContext);
	const history = useHistory();
	const classes = useStyles();

	return (
		<Container className={classes.container} maxWidth="xs">
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<TextField
								fullWidth
								label="Email"
								name="email"
								value={resetPasswordDetails.email}
								disabled={false}
								onChange={(e) =>
									setResetPasswordDetails((pv: any) => ({
										...pv,
										email: e.target.value,
									}))
								}
								size="small"
								type="string"
								variant="outlined"
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								fullWidth
								label="Code"
								name="code"
								value={resetPasswordDetails.code}
								onChange={(e) =>
									setResetPasswordDetails((pv: any) => ({
										...pv,
										code: e.target.value,
									}))
								}
								disabled={false}
								size="small"
								type="string"
								variant="outlined"
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								fullWidth
								value={resetPasswordDetails.newPassword}
								onChange={(e) =>
									setResetPasswordDetails((pv: any) => ({
										...pv,
										newPassword: e.target.value,
									}))
								}
								label="New Password"
								name="newPassword"
								size="small"
								type="password"
								variant="outlined"
							/>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={12}>
					<Button
						color="secondary"
						onClick={resetPassword}
						fullWidth
						disabled={false}
						type="submit"
						variant="contained">
						Reset Password
					</Button>
				</Grid>
			</Grid>
		</Container>
	);
};

export default ResetPassword;
