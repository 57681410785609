import { deleteRequest, getRequest, patchRequest, postRequest } from "api/base";
import { formatBots, FormattedBot } from "api/formatters/bots";
import {
  CreateBotsPayload,
  DeleteBot,
  Instance,
  Platform,
  PredefinedBotArray,
  RestartBot,
} from "types";
import { RBS_LIMIT } from "utils";

export const getBotsByTag = async (): Promise<{
  data: FormattedBot[];
  rb0Available: number;
  rb1Available: number;
  rb2Available: number;
}> => {
  try {
    const rRB0 = await getRequest("/service/bots/tag/rb0");
    const rRB1 = await getRequest("/service/bots/tag/rb1");
    const rRB2 = await getRequest("/service/bots/tag/rb2");
    const newList = formatBots(
      rRB0?.data?.data,
      rRB1?.data?.data,
      rRB2?.data?.data
    );

    return {
      data: newList,
      rb0Available: RBS_LIMIT[0] - (rRB0?.data?.data?.length || 0),
      rb1Available: RBS_LIMIT[1] - (rRB1?.data?.data?.length || 0),
      rb2Available: RBS_LIMIT[2] - (rRB2?.data?.data?.length || 0),
    };
  } catch (err) {
    console.log("err", err);
    return {
      data: [],
      rb0Available: 0,
      rb1Available: 0,
      rb2Available: 0,
    };
  }
};

export const getBotsByPlatform = async ({
  platform,
  platformAccount,
}: {
  platform: Platform;
  platformAccount: string;
}): Promise<Instance[]> => {
  try {
    const response = await getRequest(
      `/service/bots/?platform=${platform}&platform_account=${platformAccount}`
    );
    return response.data.data;
  } catch (err) {
    console.error("err", err);
    return [];
  }
};

export const getMachineTypes = async (
  platform: Platform
): Promise<{ key: string; value: string }[]> => {
  try {
    const response = await getRequest(
      `/service/bots/machine-types?platform=${platform}`
    );
    return response.data.data;
  } catch (err) {
    console.error("err", err);
    return [];
  }
};

export const createBots = async (payload: CreateBotsPayload) => {
  try {
    const response = await postRequest("/service/bots/", payload);

    return response;
  } catch (err) {
    console.log("err", err);
  }
};

export const restartBot = async (bots: RestartBot[]) => {
  try {
    const response = await postRequest("/service/bots/restart", { bots });

    return response;
  } catch (err) {
    console.log("err", err);
  }
};

export const deleteBot = async (bots: DeleteBot[]) => {
  try {
    const response = await postRequest("/service/bots/delete", { bots });

    return response;
  } catch (err) {
    console.log("err", err);
  }
};

export const deleteWhere = async ({
  platform,
  platformAccount,
  region,
  name,
}: {
  platform: Platform;
  platformAccount: string;
  region?: string;
  name?: string;
}) => {
  try {
    const response = await deleteRequest(
      `/service/bots/?platform=${platform}&platform_account=${platformAccount}${
        region ? `&region=${region}` : ""
      }${name ? `&name=${name}` : ""}`
    );
    return response;
  } catch (err) {
    console.log("err", err);
  }
};

export const restartWhere = async ({
  platform,
  platformAccount,
  region,
  name,
}: {
  platform: Platform;
  platformAccount: string;
  region?: string;
  name?: string;
}) => {
  try {
    const response = await patchRequest("/service/bots/", {
      platform,
      platform_account: platformAccount,
      region,
      name,
    });
    return response;
  } catch (err) {
    console.log("err", err);
  }
};

export const getRegions = async (platform: Platform) => {
  try {
    const response = await getRequest(
      `/service/bots/regions?platform=${platform}`
    );
    return response.data.data;
  } catch (err) {
    console.log("err", err);
  }
};

export const createDefaultBotnet = async () => {
  try {
    const response = await postRequest("/service/bots/create/default");

    return response;
  } catch (err) {
    console.log("err", err);
  }
};

export const getPredefinedBotArrays: () => Promise<
  PredefinedBotArray[]
> = async () => {
  try {
    const response = await getRequest("service/predefined/");
    return response.data;
  } catch (err) {
    console.log("err", err);
    return [];
  }
};

export const createPredefined = async ({
  id,
  botnetName,
}: {
  id: string;
  botnetName: string;
}) => {
  try {
    return postRequest("/service/predefined/", { id, botnet_name: botnetName });
  } catch (err) {
    console.log("err", err);
  }
};
