import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			'& > * + *': {
				marginLeft: theme.spacing(2),
			},
		},
	})
);

const CircularLoading = ({ text }: { text?: string }) => {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			{text}
			<CircularProgress color='secondary' />
		</div>
	);
};

export default CircularLoading;