import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useStore } from 'react-hookstore';
import { MODAL_STORE } from 'store';
import styled from 'styled-components';
import ActionsList from './ActionsList';

const StyledDialog = styled.div`
	display: grid;
	width: 100%;
	grid-template-columns: 0.5fr 4fr 0.5fr;
`;

const DialogTitleWrapper = styled.div`
	grid-column: 2;
`;

const Modal = () => {
	const [modalState, setModalState] = useStore<any>(MODAL_STORE);
	const { type }: any = modalState;
	if (!type) return <></>;
	//@ts-ignore
	const ModalComponent: any = modalTypes[type];
	if (!ModalComponent) { 
		console.log('tried to initiate un existent modal type:', type);		
		return <></>;
	}
	//@ts-ignore
	const modalTitle = modalTitles[type];

	const handleClose = () => {
		setModalState((pv: any) => ({ ...pv, isOpen: false, type: '' }));
	};

	return (
		<Dialog onClose={handleClose} maxWidth={false} aria-labelledby="simple-dialog-title" open={modalState?.isOpen}>
			<StyledDialog>
				{ 
					modalState?.type !== 'actionsList' 
				&& <div style={{'cursor': 'pointer', 'padding': '8px'}} onClick={() => setModalState((pv: any) => ({...pv, isOpen: true, type:'actionsList'}))}>
					<ArrowBackIcon/>
				</div>
				}
				<DialogTitleWrapper>
					<DialogTitle id="simple-dialog-title" style={ { textAlign: 'center' } }>
						{modalTitle}
					</DialogTitle>
				</DialogTitleWrapper>
				<div style={{ gridColumn: 2 }}>
					<ModalComponent
						closeModal={handleClose}
						modalProps={modalState?.dialogProps} />
				</div>
			</StyledDialog>
		</Dialog>
	);
};

const modalTypes = {
	'actionsList': ActionsList,
};

const modalTitles = {
	'actionsList': 'Actions',
};

export default Modal;
