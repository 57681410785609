import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';

export const useStyles = makeStyles({
	table: {
		minWidth: 900,
	},
});

export const SC = {
	CustomBotTableWrapper: styled.div`
		display: grid;
		justify-items: center;
		align-content: center;
`,
	ButtonWrapper: styled.div``,
	InputWrapper: styled.div`
		width: 255px;
`
};
