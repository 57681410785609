import { createStore } from 'react-hookstore';
import { APIStore } from 'types';

export const MODAL_STORE = 'MODAL_STORE';
export const API_STORE = 'API_STORE';
export const VIEW_STORE = 'VIEW_STORE';

createStore<{ selectedSplit: 0 | 1 | 2 | 3 }>(VIEW_STORE, { selectedSplit: 0 });
createStore(MODAL_STORE, { isOpen: false, type: "", modalProps: {} });

createStore<APIStore>(API_STORE, {
	refetchAllDroplets: false,
	bots: [],
	rb2Available: 0,
	rb1Available: 0,
	rb0Available: 0,
});

