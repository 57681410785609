/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable react/prop-types */

import Tab from '@material-ui/core/Tab';
import { TableTabs } from 'components';
import { a11yProps } from 'components/Table/utils';
import React from 'react';

const BotsTable = ({ tabsFilter, setTabsFilter }: any) => {

	return (
		<>
			<TableTabs 
				tabsFilter={tabsFilter}
				setTabsFilter={setTabsFilter}
			>
				<Tab label={'Botnet I'} { ...a11yProps(0) } />
				<Tab label={'Botnet II'}  { ...a11yProps(1) } />
				<Tab label={'Botnet III'} { ...a11yProps(2) } />
			</TableTabs>
		</>
	);
};

export default BotsTable;
