import AuthProvider from 'auth-context';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AppRoutes from './router';

const App = () => {

	return (
		<Router>
			<AuthProvider>
				<AppRoutes />
				<ToastContainer />
			</AuthProvider>
		</Router>
	);
};

export default App;
