import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { AuthContext, AuthStatus } from 'auth-context';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { ReactComponent as Logo } from './logo.svg';

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
	},
	menuButton: {
		marginRight: theme.spacing(2),
	},
	title: {
		flexGrow: 1,
	},
}));

const AppHeader = () => {
	const classes = useStyles();
  const { signOut,  authStatus, userAttributes } = useContext(AuthContext);
	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);
	const history = useHistory();
	const handleMenu = (event: any) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<AppBar style={{ display: 'flex', backgroundColor: '#a0bed1' }}>
			<Toolbar style={{ display: 'flex' }}>
				<Logo height='32px' />
				{authStatus === AuthStatus.SignedIn && (
					<div style={{ marginLeft: 'auto', color: '#c3ff1a' }}>
						<IconButton
							aria-label='account of current user'
							aria-controls='menu-appbar'
							aria-haspopup='true'
							onClick={handleMenu}
							color='inherit'>
							<AccountCircle />
						</IconButton>
						<Menu
							id='menu-appbar'
							anchorEl={anchorEl}
							anchorOrigin={{
								vertical: 'top',
								horizontal: 'right',
							}}
							keepMounted
							transformOrigin={{
								vertical: 'top',
								horizontal: 'right',
							}}
							open={open}
							onClose={handleClose}>
							<MenuItem disabled={true}>
								{userAttributes.username}
							</MenuItem>
							<MenuItem
								onClick={() => {
									signOut();
									history.push('/signin');
								}}>
								Sign Out
							</MenuItem>
						</Menu>
					</div>
				)}
			</Toolbar>
		</AppBar>
	);
};

export default AppHeader;

				{/* <IconButton edge="end" className={classes.menuButton} color="inherit" aria-label="menu">
					<MenuIcon />
				</IconButton> */}