import { FitAddon } from "@xterm/addon-fit";
import { IDisposable } from "@xterm/xterm";
import React, { useEffect, useRef, useState } from "react";
import { Terminal as XTerm } from "xterm";
import "xterm/css/xterm.css";

interface Props {
  label: string;
  onChange: (value: string) => void;
}

const Terminal: React.FC<Props> = ({ label, onChange }) => {
  const terminalRef = useRef<HTMLDivElement | null>(null);
  const [terminal] = useState(new XTerm());
  const [onKeyHandler, setOnKeyHandler] = useState<IDisposable>();

  useEffect(() => {
    for (const line of label.split("\n")) {
      terminal.writeln(line);
    }
  }, [label]);

  useEffect(() => {
    if (!terminalRef.current) {
      return;
    }
    const fitAddon = new FitAddon();
    terminal.loadAddon(fitAddon);
    terminal.open(terminalRef.current);
    fitAddon.fit();

    return () => {
      terminal.dispose();
    };
  }, [terminalRef]);

  useEffect(() => {
    onKeyHandler?.dispose();
    const onKey = terminal.onKey((e) => {
      const event = e.domEvent;
      const printable = !event.altKey && !event.ctrlKey && !event.metaKey;
      switch (event.key) {
        case "ArrowUp":
          return;
        case "ArrowDown":
          return;
        case "ArrowRight":
          return handleArrowRight();
        case "ArrowLeft":
          if (terminal.buffer.active.cursorX) {
            terminal.write("\x1b[D");
          }
          return;
        case "Enter":
          return handleEnter();
        case "Backspace":
          if (terminal.buffer.active.cursorX) {
            terminal.write("\b \b");
          }
          return;
        default:
          if (printable) {
            terminal.write(e.key);
          }
          break;
      }
    });
    setOnKeyHandler(onKey);
  }, [terminal, onChange]);

  function handleEnter() {
    const input =
      terminal.buffer.active
        .getLine(terminal.buffer.active.baseY + terminal.buffer.active.cursorY)
        ?.translateToString()
        .trim() || "";
    onChange(input);
    terminal.writeln("");
  }

  function handleArrowRight() {
    const lineLength =
      terminal.buffer.active
        .getLine(terminal.buffer.active.cursorY)
        ?.translateToString()
        .trim().length || 0;
    if (terminal.buffer.active.cursorX < lineLength) {
      terminal.write("\x1b[C");
    }
  }

  return (
    <div
      ref={terminalRef}
      style={{
        border: "1px solid black",
        fontFamily: "monospace",
        overflow: "auto",
      }}
    />
  );
};

export default Terminal;
