/* eslint-disable react/prop-types */
import AppBar from '@material-ui/core/AppBar';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';

const useStyles = makeStyles((theme: any) => ({
	root: {
		flexGrow: 1,
		backgroundColor: theme.palette.background.paper,
	},
}));

const TableTabs = (props: any) => {
	const classes = useStyles();
	const { setTabsFilter, tabsFilter, children } = props;

	const handleChange = (event: any, newValue: any) => {
		setTabsFilter(newValue);
	};

	return (
		<div className={classes.root}>
			<AppBar position="static">
				<Tabs value={tabsFilter} onChange={ handleChange } aria-label="table tabs">
					{children}
				</Tabs>
			</AppBar>
		</div>
	);
};

export default TableTabs;