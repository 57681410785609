/* eslint-disable react/prop-types */
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles((theme) => ({
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
		width: 255,
		maxWidth: 300,
	},
	chips: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	chip: {
		margin: 2,
	},
	noLabel: {
		marginTop: theme.spacing(3),
	},
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};

function getStyles(name:any, inputValue:any, theme:any) {
	return {
		fontWeight:
			inputValue?.indexOf(name) === -1
				? theme.typography.fontWeightRegular
				: theme.typography.fontWeightMedium,
	};
}

const SelectInput = (props: any) => {
	const { label, options, inputValue, onChange, disabled = false, required = false } = props;
	const classes = useStyles();
	const theme = useTheme();

	return (
		<FormControl className={classes.formControl}>
			<InputLabel id="demo-mutiple-chip-label">{label}</InputLabel>
			<Select
				labelId="demo-mutiple-chip-label"
				id="demo-mutiple-chip"
				value={inputValue}
				required={required}
				disabled={disabled}
				onChange={onChange}
				MenuProps={MenuProps}
			>
				{options.map(({ name, value }: any) => (
					<MenuItem key={value} value={value} style={ getStyles(name, inputValue, theme) }>
						{name}
					</MenuItem>
				))}
			</Select>

		</FormControl>);
};

export default SelectInput;