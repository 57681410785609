import { postRequest } from 'api/base';
import { Instance } from 'types'

export const describe: () => Promise<Instance[]> = async () => {
	try {
		const response = await postRequest('/service/herders/describe/all');
		return response?.data?.data?.instances || [];
	} catch (err) {
		console.log('err', err);
		return [];
	}
};

export const start = async (ids: string[]) => {
	try {
		const response = await postRequest('/service/herders/start', { ids });

		return response;
	} catch (err) {
		console.log('err', err);
	}
};

export const stop = async (ids: string[]) => {
	try {
		const response = await postRequest('/service/herders/stop', { ids });

		return response;
	} catch (err) {
		console.log('err', err);
	}
};
// export const stopHerder = async ({ id }) => {
// 	try {
// 		const response = await postRequest('/service/herders/stop', { id });

// 		return response;
// 	} catch(err){
// 		console.log('err',err);			
// 	}
// };