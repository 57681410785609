import Button from '@material-ui/core/Button';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { BotsAPI } from 'api';
import { CircularLoading } from 'components';
import { useState } from 'react';
import { useStore } from 'react-hookstore';
import { toast } from 'react-toastify';
import { API_STORE } from 'store';
import { APIStore } from 'types';
import { SC } from './style';

interface IsDefaultBotnetAvailableInput {
	rb0: number,
	rb1: number,
	rb2: number,
}

export const DEFAULT_BOTNET_RBS_COUNT = {
	0: 36,
	1: 57,
	2: 7
};

const isDefaultBotnetAvailable = ({
	rb0,
	rb1,
	rb2,
}: IsDefaultBotnetAvailableInput) => {
	const isEnoughRB0 = DEFAULT_BOTNET_RBS_COUNT[0] <= rb0;
	const isEnoughRB1 = DEFAULT_BOTNET_RBS_COUNT[1] <= rb1;
	const isEnoughRB2 = DEFAULT_BOTNET_RBS_COUNT[2] <= rb2;

	return isEnoughRB0 && isEnoughRB1 && isEnoughRB2;
};

const CreateDefaultBotnet = () => {
	const [{ refetchAllDroplets, bots, ...apiData }, setApiRefetchControllerState] =
		useStore<APIStore>(API_STORE);
	const [isLoading, setIsLoading] = useState(false);

	const createButtonHandler = async () => {
		try {
			setIsLoading(true);
			const isEnoughSpace = isDefaultBotnetAvailable({
				rb0: apiData.rb0Available,
				rb1: apiData.rb1Available,
				rb2: apiData.rb2Available,
			});
			if (!isEnoughSpace) {
				toast.warn("There is not Enough Space To Create Default Plan", {
					autoClose: false,
				});
				setIsLoading(false);
				return;
			}
			const response = await BotsAPI.createDefaultBotnet();
			toast.success('Created Default Botnet Successfully!');
			if (response.status === 200)
				setTimeout(() => {
					setIsLoading(false);
					//@ts-ignore
					setApiRefetchControllerState((pv: any) => ({
						...pv,
						refetchAllDroplets: true,
					}));
				}, 10000);
		} catch (err) {
			setIsLoading(false);
			console.log('err', err);
		}
	};

	return (
		<SC.NewProjectWrapper>
			<div style={{ fontSize: "20px" }}>
				This Action Will Create 100 Bots; 50 in Botnet 1 And 50 In
				Botnet 2; In The Following Regions:
			</div>
			<div style={{ display: "grid", gridTemplateColumns: "0.2fr 1fr" }}>
				<ListItem>
					<ListItemText primary="NEW YORK (12)" secondary={null} />
				</ListItem>
				<ListItem>
					<ListItemText
						primary="SAN FRANCISCO (12)"
						secondary={null}
					/>
				</ListItem>
				<ListItem>
					<ListItemText primary="AMSTERDAM (12)" secondary={null} />
				</ListItem>
				<ListItem>
					<ListItemText primary="FRANKFURT (12)" secondary={null} />
				</ListItem>
				<ListItem>
					<ListItemText primary="SINGAPORE (12)" secondary={null} />
				</ListItem>
				<ListItem>
					<ListItemText primary="BANGALORE (14)" secondary={null} />
				</ListItem>
				<ListItem>
					<ListItemText primary="LONDON (12)" secondary={null} />
				</ListItem>
				<ListItem>
					<ListItemText primary="TORONTO (14)" secondary={null} />
				</ListItem>
			</div>
			<SC.ButtonWrapper>
				<Button
					variant="contained"
					color="primary"
					disabled={isLoading || refetchAllDroplets}
					onClick={createButtonHandler}>
					{!isLoading ? (
						"Create"
					) : refetchAllDroplets ? (
						"Fetching Bots, Please Wait"
					) : (
						<CircularLoading
							text={"Please Wait, It can take up to a minute"}
						/>
					)}
				</Button>
			</SC.ButtonWrapper>
		</SC.NewProjectWrapper>
	);
};

export default CreateDefaultBotnet;
