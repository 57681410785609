import { AuthContext, AuthStatus } from 'auth-context';
import { AppHeader } from 'components';
import { MainPage, ResetPassword, SetNewPassword, Signin } from 'pages';
import { useContext, useEffect } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';

const NotFound = () => {
	const { authStatus, isNewUser } = useContext(AuthContext);
	const history = useHistory();
	// eslint-disable-next-line no-undef
	useEffect(() => {
		if (authStatus != AuthStatus.SignedIn && !isNewUser) {
			history.push('/signin');
		} else if (authStatus === AuthStatus.SignedIn) {
			history.push('/');
		}
	}, [authStatus, isNewUser]);

	return <div></div>;
};

const ProtectedRoutes = () => {
	return (
		<>
			<AppHeader />
			<Switch>
				<Route path="/" exact render={() => <MainPage />} />
				<Route component={() => <NotFound />} />
			</Switch>
		</>
	);
};

const NotProtectedRoutes = () => {
	return (
		<>
			<AppHeader />
			<Switch>
				<Route path="/signin" exact render={() => <Signin />} />
				<Route path="/set-password" exact render={() => <SetNewPassword />} />
				<Route path="/reset-password" exact render={ () => <ResetPassword />} />
				<Route component={() => <NotFound />} />
			</Switch>
		</>
	);
};

const AppRoutes = () => {
	const { authStatus } = useContext(AuthContext);

	if (authStatus === AuthStatus.SignedIn) {
		return <ProtectedRoutes />;
	}  else {
		return <NotProtectedRoutes />;
	}
};

export default AppRoutes;