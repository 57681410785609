import IconButton from '@material-ui/core/IconButton';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';
import RestartIcon from '@material-ui/icons/Restore';
import clsx from 'clsx';

const useToolbarStyles = makeStyles((theme: any) => ({
	root: {
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(1),
	},
	highlight:
		theme.palette.type === 'light'
			? {
				color: theme.palette.secondary.main,
				backgroundColor: lighten(
					theme.palette.secondary.light,
					0.85
				),
			  }
			: {
				color: theme.palette.text.primary,
				backgroundColor: theme.palette.secondary.dark,
			  },
	title: {
		flex: '1 1 100%',
	},
}));

interface Props {
	numSelected: number,
	deleteSelected: () => void,
	restartSelected: () => void,
	isRowSelected: boolean
}

const TableToolbar = (props: Props) => {
	const classes = useToolbarStyles();
	const { numSelected, deleteSelected, restartSelected } = props;
	const title = '';

	return (
		<Toolbar
			className={clsx(classes.root, {
				[classes.highlight]: numSelected > 0,
			})}>
			{numSelected > 0 ? (
				<Typography
					className={classes.title}
					color='inherit'
					variant='subtitle1'
					component='div'>
					{numSelected} selected
				</Typography>
			) : (
				<Typography
					className={classes.title}
					variant='h6'
					id='tableTitle'
					component='div'>
					{title}
				</Typography>
			)}

			{numSelected > 0 ? (
				<>
					<Tooltip title='Delete'>
						<IconButton
							aria-label='delete'
							onClick={deleteSelected}>
							<DeleteIcon />
						</IconButton>
					</Tooltip>
					<Tooltip title='Restart'>
						<IconButton
							aria-label='restart'
							onClick={restartSelected}>
							<RestartIcon />
						</IconButton>
					</Tooltip>
				</>
			) : null}
		</Toolbar>
	);
};

export default TableToolbar;
