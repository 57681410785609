import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useStore } from 'react-hookstore';
import { MODAL_STORE } from 'store';

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		maxWidth: 360,
		backgroundColor: theme.palette.background.paper,
	},
}));

const ActionsList = () => {
	const [, setModalState] = useStore(MODAL_STORE);
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<List component="nav" aria-label="main mailbox folders" style={ { width: '42ch', height: '18em' } }>
				<Divider />
				<ListItem button onClick={ () => setModalState((pv: any) => ({ ...pv, isOpen: true, type: 'createDroplet' })) }>
					<ListItemText primary="Start Customizable Bot" />
				</ListItem>
				<Divider />
				<ListItem button onClick={ () => setModalState((pv: any) => ({ ...pv, isOpen: true, type: 'createDefaultBotnet' })) }>
					<ListItemText primary="Start Default Botnet" />
				</ListItem>
				<Divider />
			</List>
		</div>
	);
};

export default ActionsList;