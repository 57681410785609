import { WebCLIMenuState } from "types";

export const SLUGS = [
  {
    value: "s-4vcpu-8gb",
    name: "small",
  },
  {
    value: "s-8vcpu-16gb",
    name: "medium",
  },
  {
    value: "g-8vcpu-32gb",
    name: "large",
  },
  {
    value: "c-16",
    name: "extra large",
  },
];

export const bh1_ids = [114871092, 114871058, 114871042];
export const bh2_ids = [114871295, 114871252, 114871273];
export const bh3_ids = [114871413, 114871380, 114871346];

export const defaultWebCLIMenuState: WebCLIMenuState = {
  herderCount: 0,
  predefinedList: [],
  botnetName: "",
};

export const DEFAULT_BOTNET_RBS_COUNT = {
  0: 36,
  1: 57,
  2: 7,
};

export const ACTIVE_BOTS_HEADERS = [
  { id: "region", numeric: false, disablePadding: true, label: "Region" },
  { id: "status", numeric: false, disablePadding: true, label: "Status" },
  {
    id: "ipaddress",
    numeric: false,
    disablePadding: true,
    label: "IP Address",
  },
  { id: "size", numeric: true, disablePadding: true, label: "Size" },
];

/**AWS COGNITO ERRORS */
export const NEW_PASSWORD_REQUIRED_ERROR = "newPasswordRequiredError";
export const WRONG_CREDS_ERROR = "Incorrect username or password.";
export const INVALID_PASSWORD = "InvalidPasswordException";

export const RBS_LIMIT = {
  0: 40,
  1: 60,
  2: 60,
};

export const cliFirstMenuText = `[1] Control Herders
[2] Control Bots
[3] Create predefined bot array
[q] Quit`;

export const cliInitialMenuText = `Welcome to the Web CLI! What would you like to do?
${cliFirstMenuText}`;

export const cliHerderMenuText = `[1] Start Herders
[2] Stop Herders
[3] Print Herder Statuses
[q] Return to main menu`;

export const cliBotMenuText = `[1] Create Custom Botnet
[2] Restart Bots
[3] Delete Bots
[4] Print Instances
[q] Return to main menu`;

export const cliResetartBotsMenuText = `[1] Restart All Bots
[2] Restart Bots in a Specific Region
[3] Restart a Specific Botnet by Name
[q] Return to main menu`;

export const cliBotPlatformsMenuText = `[1] Digital Ocean
[2] IBM
[3] Azure
[4] Vultr
[5] GCP
[6] UpCloud
[q] Return to main menu`;

export const cliBotnetNameMenuText =
  "Enter botnet name (consist of lowercase letters (a-z) and numbers):";

export const cliDeleteBotsMenuText = `[1] Delete All Bots
[2] Delete Bots in a Specific Region
[3] Delete a Specific Botnet by Name
[q] Return to main menu`;
