import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';

export const MainWrapper = styled.div`
	display: grid;
	margin-top: 5%;
	padding: 26px;
	grid-template-columns: 0.3fr 4fr 0.3fr;
	grid-template-rows: 0.1fr 1fr;
`;

export const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		backgroundColor: theme.palette.background.paper,
		display: 'flex',
		// height: 224,
		gridColumn: '2',
		gridRow: '2',
	},
	tabs: {
		borderRight: `1px solid ${theme.palette.divider}`,
	},
}));
